import { EXTERNAL_URLS } from 'lib/constants/routes';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * We redirect the base URL of our app (app.shopcanal.com) to our
 * marketing homepage (www.shopcanal.com)
 */
const IndexPage = (): null => {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // If the user has landed on app.shopcanal.com, redirect them to our homepage (shopcanal.com)
      if (window.location.href === EXTERNAL_URLS.APP) {
        window.location.replace(EXTERNAL_URLS.WEBSITE);
        return;
      }
      // If the user lands on a legacy blog post URL, redirect them to the new blog
      if (window.location.href.includes('blog.shopcanal.com')) {
        window.location.replace(EXTERNAL_URLS.BLOG);
        return;
      }
    }

    router.push('login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default IndexPage;
